
import Vue from "vue";
import {login} from "@/api/userApi";
import { setCookie } from "typescript-cookie"

export default Vue.extend({
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      loading: false,
      showPass: false,
    }
  },
  computed: {
    clean(): boolean {
      return Object.values(this.user).every((val) => val != '')
    }
  },
  methods: {
    onClose(){
      this.$emit('close-dialog')
    },
    openRegister() {
      this.$emit('close-dialog')
      this.$emit('open-register')
    },
    login() {
      this.loading = true;
      login(this.user.username, this.user.password).then(res => {
        setCookie('token', res, {sameSite: "lax"});
        this.$emit('login-success')
      }).catch(reason => {
        this.$emit('login-error', reason)
      }).finally( () => {
            this.loading = false;
            (this.$refs.form as any).reset();
            this.onClose();
      });
    }
  }
})
